<template lang="pug">
.w-100.w-md-35.mx-auto.text-center.brand-auth-login-box
  .brand-logo(v-if="!isWhitelabelDomain")
    om-logo-svg.mb-3(v-if="$route.name === 'oauth_login'")
    a(v-else :href="getSalesLink()")
      om-logo-svg.mb-3
  .brand-logo.d-flex.justify-content-center(v-else-if="isWhitelabelDomain && WLcustomLogo")
    img(style="max-height: 12em" :src="WLcustomLogo")
  .brand-title-section.pb-2(v-if="!isWhitelabelDomain") {{ $t('login.loginWithSocial') }}
  .brand-btn-wrapper(v-if="!isWhitelabelDomain")
    .row.p-0.px-sm-4.d-flex.justify-content-center
      .mr-4.social-circle-hover-box.cursor-pointer(@click="loginWithSocial('facebook')")
        .social-circle-hover
          facebook-svg
      .social-circle-hover-box.cursor-pointer(@click="loginWithSocial('google')")
        .social-circle-hover
          google-svg
    template(v-if="hasSocialErrorMessage")
      .form-text.text-danger {{ socialErrorMessage }}
    .brand-caption-section.font-size-1.mt-2.mb-4 {{ $t(isWhitelabelDomain ? 'WL.login' : 'login.loginWithEmail', { brand: this.WLbrandName }) }}
    form.mb-0
      .form-group.row
        .col-12
          input#email.form-control.form-control-lg.text-indent-sm(
            :placeholder="$t('login.emailPlaceholder')"
            type="text"
            :class="{ 'is-invalid': $v.email.$error || this.errorMessage }"
            ref="emailInput"
            v-model.trim="email"
          )
          .text-danger.text-left.font-size-0--8125(v-if="$v.email.$error && !$v.email.required") {{ $t('requiredField') }}
      .form-group.row
        .col-12
          input#password.form-control.form-control-lg.text-indent-sm(
            :placeholder="$t('login.passwordPlaceholder')"
            type="password"
            :class="{ 'is-invalid': $v.password.$error || this.errorMessage }"
            ref="passwordInput"
            v-model.trim="password"
          )
          .text-danger.justify-content-center.mt-3.font-size-0--75(
            v-if="$v.email.required && $v.password.required && this.errorMessage"
          ) {{ this.errorMessage }}
          .text-danger.text-left.font-size-0--8125(
            v-if="$v.password.$error && !$v.password.required"
          ) {{ $t('requiredField') }}
      .d-flex.justify-content-center.align-items-center.mt-4
        invisible-recaptcha(
          v-if="siteKey"
          :class="loginButtonClasses"
          :sitekey="siteKey"
          :callback="login"
        ) {{ $t('login.login') }}
        om-button(
          v-else
          primary
          block
          large
          type="submit"
          @click.prevent="login()"
          @keydown.enter="login()"
        ) {{ $t('login.login') }}
      .row
        .col-12.col-sm-6.text-left.mt-3
          router-link.brand-forgot-pass.login-box-link(
            v-if="$route.name !== 'oauth_login'"
            :to="{ name: 'forgotten-password', query: { lang: getLangParam() } }"
            tag="a"
          ) {{ $t('login.reset_password') }}
        .col-12.col-sm-6.mt-3
          .row.justify-content-end
            .col-auto
              om-link.login-box-link(small @click="linkPrivacy") {{ $t('login.privacy') }}
            .col-auto
              om-link.login-box-link(small @click="linkTerms") {{ $t('login.terms') }}
</template>

<script>
  import { mapMutations } from 'vuex';
  import { required, email } from 'vuelidate/lib/validators';
  import router from '@/router/index';
  import InvisibleRecaptcha from 'vue-invisible-recaptcha';
  import LOGIN from '@/graphql/Login.gql';
  import queryParamToCookie from '@/mixins/queryParamToCookie';
  import cookieReferrer from '@/mixins/cookieReferrer';
  import guessLang from '@/mixins/guessLang';
  import whiteLabelling from '@/mixins/whiteLabelling';
  import { apiBaseUrl } from '@/config/url';
  import runtimeConfig from '@/config/runtime';
  import moment from 'moment';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import { getHeap } from '@/services/xray';
  import { setAccountIdInCookie } from '../../util';

  const siteKey = runtimeConfig.VUE_APP_RECAPTCHA_SITE_KEY;
  const whitelabelSiteKey = runtimeConfig.VUE_APP_RECAPTCHA_SITE_KEY_WHITELABEL;

  const backendUrl = apiBaseUrl;
  const popupWindow = (url, title, win, w, h) => {
    return win.open(
      url,
      title,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=0, left=0`,
    );
  };

  export default {
    components: { InvisibleRecaptcha },
    mixins: [guessLang, queryParamToCookie, cookieReferrer, whiteLabelling],

    props: {
      loginToOwner: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        email: '',
        password: '',
        errorMessage: '',
      };
    },

    computed: {
      siteKey() {
        return this.isWhitelabelDomain && whitelabelSiteKey ? whitelabelSiteKey : siteKey;
      },
      loginButtonClasses() {
        return getBrandedClassString({ primary: true, large: true }, ['w-100', 'design-system']);
      },
      hasSocialErrorMessage() {
        const socialErrorTransKey = ['googleAccountNotExist', 'facebookAccountNotExist'];
        return socialErrorTransKey.includes(this.$route.query.message);
      },
      socialErrorMessage() {
        const message = this.$route.query.message;
        return this.$t(`socialError.${message}`);
      },
    },

    validations() {
      const rules = {
        email: {
          required,
          isCoolEmail(v) {
            return email(v.toLowerCase());
          },
        },
        password: {
          required,
        },
      };

      return rules;
    },

    watch: {
      password(password) {
        if (password) {
          this.errorMessage = '';
        }
      },
      email(email) {
        if (email) {
          this.errorMessage = '';
        }
      },
    },

    created() {
      this.language = this.guessLanguage();
      this.$i18n.locale = this.language;
      this.email = this.$route.query.email;
    },

    methods: {
      ...mapMutations(['showAdminLoader']),
      linkPrivacy() {
        const link =
          this.getLangParam() === 'hu'
            ? 'https://www.optimonk.hu/adatvedelmi-nyilatkozat'
            : 'http://optimonk.com/privacy-policy ';
        window.open(link, '_blank');
      },
      linkTerms() {
        const link = this.$t('termsAndConditionsLink');
        window.open(link, '_blank');
      },
      async login(recaptcha) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.showAdminLoader(true);
        let errorMessage = null;
        try {
          const { data, errors } = await this.$apollo.mutate({
            mutation: LOGIN,
            variables: {
              input: {
                email: this.email.toLowerCase(),
                password: this.password,
                loginToOwner: this.loginToOwner,
                recaptcha,
              },
            },
            refetchQueries: ['GetAccount'],
          });
          const login = data.login;
          if (!login?.success) {
            errorMessage = this.$t('notifications.loginError');
          }
          if (errors) {
            const rateLimitError = errors.find((e) => e.code === 429);
            if (rateLimitError) {
              errorMessage = `${this.$t(rateLimitError.message, {
                time: moment().locale(window.om.i18n.locale).to(rateLimitError.resetAt),
              })}`;
            }
          } else if (login && login.success) {
            errorMessage = null;
            setAccountIdInCookie(login.dataAccountId);
            getHeap()?.identify?.(login.dataAccountId);
            this.$store.dispatch('login', {
              expiration: login.expiration,
              accountType: login.accountType,
              locale: login.locale,
              impersonate: login.impersonate,
            });

            if (login.redirectUrl) {
              this.showAdminLoader(true);
              window.location = login.redirectUrl;
              return;
            }
            if (login.accountType === 'multiple') {
              if (login.pendingShopifyInstall) {
                localStorage.setItem('om_redirect_after_switch_account', '/connect-shopify');
              }

              router.replace({ name: 'switch_account', query: this.$route.query });
            } else if (login.pendingShopifyInstall) {
              router.replace({ name: 'connect_shopify' });
            } else {
              this.$emit('success', login);
            }
          }
        } catch (e) {
          console.error('Login request error', e);
        }
        if (errorMessage) {
          this.$refs.emailInput.classList.add('is-invalid');
          this.$refs.passwordInput.classList.add('is-invalid');
          this.$notify({
            type: 'error',
            text: errorMessage,
          });
          this.errorMessage = errorMessage;
        }
        this.showAdminLoader(false);
      },

      loginWithSocial(type) {
        type = type === 'facebook' ? 'facebook' : 'google';
        const url = `${backendUrl}/login/${type}?lang=${this.language}${
          this.$route.name === 'oauth_login' ? '&oauth=1' : ''
        }`;

        if (window.parent !== window) {
          return this.openSocialInWindow(url);
        }

        window.location.href = url;
      },
      openSocialInWindow(url) {
        const wnd = popupWindow(url, 'OptiMonk Social Login', window, 650, 550);

        wnd.focus();

        window.addEventListener(
          'message',
          (e) => {
            const validOrigin =
              e.origin.indexOf('localhost') > -1 || e.origin.indexOf('optimonk') > -1;
            if (validOrigin) {
              let data = e.data;

              if (typeof e.data === 'string') {
                try {
                  data = JSON.parse(e.data);
                } catch (err) {
                  // error
                }
              }

              if (data.type === 'optimonk') {
                const action = data.action;

                if (action === 'login') {
                  this.$emit('success');
                  wnd.close();
                } else if (action === 'close') {
                  wnd.close();
                }
              }
            }
          },
          false,
        );
      },
    },
  };
</script>

<style lang="sass">
  .login-box-link
    font-weight: 400!important
    font-size: 0.75rem
    &:hover
      text-decoration: underline
</style>
